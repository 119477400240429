import React from 'react';

export default function Content({ children, className, style }) {
  return (
    <div
      className={className}
      style={{
        zIndex: 1,
        flexGrow: 1,
        //backgroundColor: 'green',
        ...style,
      }}
    >
      {children}
    </div>
  );
}
