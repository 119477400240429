import React from 'react';

export default function Footer({ children, className, style }) {
  return (
    <div
      id="bodyFooter"
      className={className}
      style={{
        zIndex: 1,
        //backgroundColor: 'blue',
        ...style,
      }}
    >
      {children}
    </div>
  );
}
