import React, { useState } from 'react';

import Background from './Background';

import { Step01, Step02, Step03, Step04, StepDetailsForm, StepSuccess } from './funnelSteps';

export default function Funnel({
  tx,
  externalContracts,
  writeContracts,
  gasPrice,
  address,
  userProvider,
  localProvider,

  walletIsConnected,
  walletOwnershipIsVerified,
  setWalletOwnershipIsVerified,
  accountView,
}) {
  // TODO: delete funnelSteps/Step01/ and set useState(0)
  const [currentStepIndex, setCurrentStepIndex] = useState(1);
  const [userName, setUserName] = useState('');
  const [userWalletDetails, setUserWalletDetails] = useState({
    address: null,
    privateKey: null,
    walletFile: null,
    password: null,
  });

  console.log({ currentStepIndex });
  console.log({ externalContracts });

  const goToNextStep = () => {
    setCurrentStepIndex(currentStepIndex + 1);
  };

  const stepsList = [
    {
      view: <Step01 goToNextStep={goToNextStep} />,
      //backgroundUrl: 'https://imagedelivery.net',
    },
    {
      view: <Step02 goToNextStep={goToNextStep} setUserName={setUserName} />,
      //backgroundUrl: 'https://imagedelivery.net',
    },
    {
      view: <Step03 goToNextStep={goToNextStep} />,
      //backgroundUrl: 'https://imagedelivery.net',
    },
    {
      view: <Step04 goToNextStep={goToNextStep} />,
      //backgroundUrl: 'https://imagedelivery.net',
    },

    {
      view: (
        <StepDetailsForm
          goToNextStep={goToNextStep}
          //
          address={address || userWalletDetails.address}
          userWalletDetails={userWalletDetails}
          setUserWalletDetails={setUserWalletDetails}
          userProvider={userProvider}
          accountView={accountView}
          //
          walletIsConnected={walletIsConnected}
          walletOwnershipIsVerified={walletOwnershipIsVerified}
          setWalletOwnershipIsVerified={setWalletOwnershipIsVerified}
        />
      ),
      //backgroundUrl: 'https://imagedelivery.net',
    },
    {
      view: (
        <StepSuccess
          goToNextStep={goToNextStep}
          //
          userName={userName}
          userWalletDetails={userWalletDetails}
          address={address || userWalletDetails.address}
          //
          tx={tx}
          userProvider={userProvider}
          localProvider={localProvider}
          externalContracts={externalContracts}
          writeContracts={writeContracts}
          gasPrice={gasPrice}
        />
      ),
      //backgroundUrl: 'https://imagedelivery.net',
    },
  ];

  const activeStepView = stepsList[currentStepIndex].view;

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          minHeight: '100vh',
          zIndex: 2,
        }}
      >
        {activeStepView}
      </div>
    </>
  );
}
