import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthToken } from '../hooks';

export default function PrivateRoute({ children, ...props }) {
  const { checkAuthToken } = useAuthToken();
  const isAuthenticated = checkAuthToken();
  return (
    <Route
      {...props}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
