import React, { useRef, useState, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

import Address from './Address';
import Balance from './Balance';
import WalletModal from './WalletModal';
import { ellipsizedAddress } from '../../helpers/ellipsizedAddress';
import { classNames, blockExplorerLink } from '../../helpers';

export default function Account({
  address,
  connectText,
  ensProvider,
  loadWeb3Modal,
  logoutOfWeb3Modal,

  userSigner,
  localProvider,
  mainnetProvider,
  price,
  walletIsConnected,
  web3Modal,
  blockExplorer,
}) {
  const ens = 'TODO'; //useDisplayAddress(ensProvider, address);
  const shortAddress = ellipsizedAddress(address);
  const [walletOpen, setWalletOpen] = useState();
  const etherscanLink = blockExplorerLink(address, blockExplorer);

  const hasEns = ens !== shortAddress;

  const connectWallet = (
    <button
      onClick={loadWeb3Modal}
      className="w-full px-12 py-4 mt-4 text-white text-lg rounded-lg bg-blue-600 disabled:bg-blue-300 hover:bg-blue-500"
    >
      {connectText || 'connect'}
    </button>
  );

  /*
  const userDisplayName = ({ mb, textAlign }) =>
    hasEns ? (
      <>
        <p>{ens}</p>
        <p>{shortAddress}</p>
      </>
    ) : (
      <p>{shortAddress}</p>
    );
  */

  const accountMenu = (
    <div>
      <Menu as="div">
        <Menu className="inline-flex items-center px-3 py-0.5 text-base leading-4 font-normal rounded-full shadow-sm bg-slate-100  dark:bg-neutral-800">
          <Address
            address={address}
            disableAddressLink={true}
            ensProvider={mainnetProvider}
            blockExplorer={blockExplorer}
            size={'short'}
          />
        </Menu>
      </Menu>
      <WalletModal
        address={address}
        provider={localProvider}
        signer={userSigner}
        ensProvider={mainnetProvider}
        price={price}
        open={walletOpen}
        setOpen={setWalletOpen}
      />
    </div>
  );

  return <>{walletIsConnected ? accountMenu : connectWallet}</>;
}
