import React, { useState } from 'react';
import { notification } from 'antd';
import { ethers } from 'ethers';
import { useBalance } from 'eth-hooks';

import RampWidget from './RampWidget';

const MOONTOTEM_MINT_PRICE_ETH = '0.01';

export default function ActionPanel({
  userWalletDetails,
  tokenId,
  address,
  tx,
  externalContracts,
  writeContracts,
  userProvider,
  localProvider,
  gasPrice,
}) {
  console.log({ address, userWalletDetails });

  const userEthBalance = useBalance(localProvider, address)?.toString();

  console.log({ userEthBalance });

  // TODO: check if user created wallet with us or is using metamask
  // https://docs.ethers.io/v4/api-wallet.html

  const [showRampWidget, setShowRampWidget] = useState(false);

  const mintValue = ethers.utils.parseEther(MOONTOTEM_MINT_PRICE_ETH);

  const rampWidgetOnEvent = () => {
    console.log('rampWidgetOnEvent onEvent');
  };

  // TODO: https://docs.ethers.io/v5/api/signer/#Wallet-fromEncryptedJson
  const mint = async (to, tokenId) => {
    console.log('mint:');
    console.log({ to, tokenId, value: mintValue });
    console.log({ externalContracts });

    try {
      const {
        1: {
          contracts: { MoonTotems },
        },
      } = externalContracts;

      console.log({ MoonTotems });
      console.log({ localProvider });

      const userCreatedWalletFileWithMemberr = userWalletDetails && userWalletDetails.walletFile;
      if (userCreatedWalletFileWithMemberr) {
        // mint totem with memberr wallet
        const signer = await ethers.Wallet.fromEncryptedJson(userWalletDetails.walletFile, userWalletDetails.password);
        const connectedSigner = signer.connect(localProvider);
        const moonTotemsContract = new ethers.Contract(MoonTotems.address, MoonTotems.abi, connectedSigner);

        const transactionResult = await moonTotemsContract.mint(to, tokenId, {
          gasPrice,
          gasLimit: 170000,
          value: mintValue,
        });

        console.log({ transactionResult });

        return;
      }

      // user is connected with metamask
      // mint with metamask
      const mintResult = await tx(
        writeContracts.MoonTotems.mint(to, tokenId, {
          gasPrice,
          gasLimit: 170000,
          value: mintValue,
        }),
      );
      console.log({ mintResult });
      if (mintResult?.value?._isBigNumber) {
        notification.success({ message: '🎉 Mint was successfull!' });
      }
    } catch (error) {
      console.error(error);
      const { message } = error;
      console.log({ message });
    }
  };

  return (
    <div className="p-5 bg-black bg-opacity-60 rounded-3xl text-white">
      <button
        onClick={() => {
          if (userEthBalance === '0') {
            notification.info({ message: 'Insufficient funds!' });
          }
          mint(address, tokenId);
        }}
        className="w-full mt-5 px-12 py-4 text-white text-lg font-bold rounded-lg bg-blue-700 hover:bg-blue-800"
      >
        🌖 Make him yours
      </button>

      <div style={{ width: '100%', textAlign: 'center' }}>
        <button
          onClick={() => setShowRampWidget(!showRampWidget)}
          className="mt-5  px-12 py-4 rounded-lg border-2 cursor-pointer text-white text-lg font-bold bg-black hover:bg-white hover:text-black"
        >
          Add Funds
        </button>
      </div>

      <RampWidget
        show={showRampWidget}
        userAddress={address}
        onEvent={rampWidgetOnEvent}
        amount={ethers.utils.parseEther('0.015')}
      />

      <p className="w-full px-12 py-10 text-2xl text-center">Are you ready to talk to your new companion?</p>

      {/*
      <a href="https://discord.com/invite/73vMqt7k7H" target="_blank" rel="noreferrer">
        <button
          onClick={() => notification.success({ message: '🎉 Mint was a success!' })}
          className="w-full px-12 py-4 mt-4 text-white text-lg rounded-lg bg-[#5856d6] hover:bg-[#3331C9]"
        >
          👾 Join your fellow holders in our Discord
        </button>
      </a>
      */}
    </div>
  );
}
