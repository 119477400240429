import React from 'react';

import { Logo } from '../../sharedComponents/';
import { Header, Content, ContentFooter, Footer } from '../../LayoutComponents/';

export default function Step01({ goToNextStep }) {
  const Titles = () => (
    <>
      <h1 className="text-4xl text-center text-white">Welcome to Moon Totems - The first talking NFT!</h1>
      {/*<h2 className="mx-5 text-center text-lg font-thin italic text-white">subtitle</h2>*/}
    </>
  );

  return (
    <>
      <Header>
        <div className="bg-black bg-opacity-60 p-5 mb-5">
          <div className="text-center my-5" style={{ fontSize: 30, textAlign: 'center', whiteSpace: 'nowrap' }}>
            <Logo />
          </div>

          <Titles />
        </div>
      </Header>

      <Content>
        <div className="flex p-5" style={{ justifyContent: 'center' }}>
          <div className="p-4 bg-black bg-opacity-60 rounded-xl" style={{ minWidth: '40%', maxWidth: '460px' }}>
            <img
              src="/images/moon_totem_4403.jpg"
              alt="Totem"
              className="w-full"
              style={{
                whiteSpace: 'normal',
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
            />
            <button
              onClick={goToNextStep}
              className="mt-5 w-full px-12 py-4 rounded-lg cursor-pointer text-white text-lg font-bold bg-blue-700 hover:bg-blue-800"
              //className="w-full text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Get yours!
            </button>
          </div>
        </div>
      </Content>

      <ContentFooter></ContentFooter>

      <Footer className="flex" style={{ justifyContent: 'center' }}></Footer>
    </>
  );
}
