/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Form, notification } from 'antd';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';

import Address from '../../../../components/scaffold-eth/Address';
import { Logo, Card } from '../../sharedComponents/';
import { Header, Content, ContentFooter, Footer } from '../../LayoutComponents/';

import WalletVerification from './WalletVerification';
import CreateWallet from './CreateWallet';

const INSERT_LEAD_MUTATION = loader('../../../../services/hasura/mutations/insertLead.graphql');

export default function StepDetailsForm({
  goToNextStep,
  //
  address,
  userWalletDetails,
  setUserWalletDetails,
  userProvider,
  accountView,
  //
  walletIsConnected,
  walletOwnershipIsVerified,
  setWalletOwnershipIsVerified,
}) {
  const [showCreateWalletFlow, setShowCreateWalletFlow] = useState(true);

  const Titles = () => <h1 className="text-4xl text-center text-white">Set up the account for your Moon Totem</h1>;

  const [form] = Form.useForm();

  const [insert_leads_one, { data, loading, error }] = useMutation(INSERT_LEAD_MUTATION);

  const onVerificationSuccess = () => {
    setWalletOwnershipIsVerified(true);
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();

    const variables = {
      lead: {
        ...values,
        address,
        customerId: process.env.REACT_APP_CUSTOMER_ID,
      },
    };

    console.log('insert_leads_one', variables);

    const result = await insert_leads_one({ variables });
    const {
      data: {
        insert_leads_one: { id, error },
      },
    } = result;

    if (error) {
      notification.error({
        message: 'Login Error',
        description: error,
        placement: 'topRight',
      });
    }

    if (!error) {
      console.log('success');
      goToNextStep();
      return;
    }
  };

  console.log({ address });

  return (
    <>
      <Header>
        <div className="bg-black bg-opacity-60 pb-5 mb-5">
          <div className="text-center my-5" style={{ fontSize: 30, textAlign: 'center', whiteSpace: 'nowrap' }}>
            <Logo />
          </div>

          <Titles />
        </div>
      </Header>

      <Content>
        <div className="flex p-5" style={{ justifyContent: 'center' }}>
          <div className="p-4 bg-black bg-opacity-60 rounded-xl" style={{ width: '100%', maxWidth: '460px' }}>
            <Form form={form} layout="vertical" className="w-full">
              {/*(address || walletIsConnected) && !showCreateWalletFlow && !address && (
                <label className="block text-white">Connected wallet</label>
              )*/}

              {!showCreateWalletFlow && !address && accountView}

              {/*address && (
                <>
                  <label className="block text-white">Connected wallet</label>
                  <Address address={address} disableAddressLink size={'long'} />
                </>
              )*/}

              {!walletIsConnected && !showCreateWalletFlow && !address && (
                <div className="w-full mt-5 text-center">
                  <a
                    onClick={() => setShowCreateWalletFlow(true)}
                    target="_blank"
                    rel="noreferrer"
                    className="text-white"
                  >
                    I don't have a wallet yet
                  </a>
                </div>
              )}

              {!address && walletIsConnected && !walletOwnershipIsVerified && (
                <div className="w-full mt-2">
                  <WalletVerification
                    address={address}
                    userProvider={userProvider}
                    onSuccess={() => onVerificationSuccess()}
                  />
                </div>
              )}

              {walletOwnershipIsVerified && (
                <div className="w-full mt-5">
                  <label className="block text-white" htmlFor="walletVerification">
                    Wallet verification
                  </label>
                  <input
                    disabled
                    value="✅ Wallet verified"
                    className="w-full px-4 py-2 mt-2 border rounded-md border-green-500 bg-green-100 text-green-800 focus:outline-none focus:ring-1 focus:ring-blue-600"
                  />
                </div>
              )}

              {(address || walletOwnershipIsVerified) && (
                <div className="flex items-baseline justify-between mt-2">
                  <button
                    onClick={handleSubmit}
                    className="w-full px-12 py-4 mt-4 text-white text-lg rounded-lg bg-blue-700 disabled:bg-blue-400 hover:bg-blue-800"
                  >
                    Get your Totem 👉
                  </button>
                </div>
              )}
            </Form>

            {showCreateWalletFlow && !address && (
              <Card>
                <CreateWallet
                  setUserWalletDetails={setUserWalletDetails}
                  setShowCreateWalletFlow={setShowCreateWalletFlow}
                />
              </Card>
            )}
          </div>
        </div>
      </Content>

      <ContentFooter></ContentFooter>

      <Footer className="flex" style={{ justifyContent: 'center' }}>
        <div className="flex" style={{ maxWidth: '850px', justifyContent: 'center' }}></div>
      </Footer>
    </>
  );
}
