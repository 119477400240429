import persistantStore from 'store';
import jsonwebtoken from 'jsonwebtoken';
import { AUTH } from '../constants';

export const useAuthToken = () => {
  const authToken = persistantStore.get(AUTH.token);

  const setAuthToken = authToken => {
    persistantStore.set(AUTH.token, authToken);
  };

  const removeAuthToken = () => {
    console.log('-> remove auth token');
    persistantStore.set(AUTH.token, null);
  };

  const checkAuthToken = () => {
    let isValid = false;
    try {
      const decodedToken = jsonwebtoken.verify(authToken, process.env.REACT_APP_JWT_SECRET);
      isValid = !!decodedToken['https://hasura.io/jwt/claims']?.['x-hasura-user-id'];
      if (!isValid) {
        removeAuthToken();
      }
      return isValid;
    } catch (err) {
      // TODO:
      console.error({ err });
      removeAuthToken();
      return false;
    }
  };

  return { authToken, setAuthToken, checkAuthToken, removeAuthToken };
};

export default useAuthToken;
