import React from 'react';

export default function Card({ title, children }) {
  return (
    <div className="border-2 border-white rounded-lg">
      {title && (
        <div className="justify-center text-lg p-4 my-2">
          <span className="w-full text-lg text-white">{title}</span>
        </div>
      )}
      <div className="justify-center p-4">{children}</div>
    </div>
  );
}
