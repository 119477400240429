import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, ApolloProvider } from '@apollo/client';
//import { HttpLink } from "apollo-link-http";

import { useAuthToken } from './hooks/';

import App from './App';
import './index.css';

//const httpLink = new HttpLink({ uri: process.env.REACT_APP_GQL_ENDPOINT });

const authMiddleware = authToken =>
  new ApolloLink((operation, forward) => {
    // set authorization header
    if (authToken) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
    }

    return forward(operation);
  });

export const useAppApolloClient = () => {
  console.log({ uri: process.env.REACT_APP_GQL_ENDPOINT });
  const httpLink = new HttpLink({ uri: process.env.REACT_APP_GQL_ENDPOINT });
  //const { authToken } = useAuthToken();
  // TODO: remove this - is only here bc of ape harbour test
  // TODO: find a better way to authenticate: make flipside endpoint public?
  const authToken = ''; //process.env.REACT_APP_TEMP_AUTH_TOKEN;

  return new ApolloClient({
    link: authMiddleware(authToken).concat(httpLink),
    cache: new InMemoryCache(),
  });
};

export default function GqlClient({ children }) {
  const apolloClient = useAppApolloClient();
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}

ReactDOM.render(
  <GqlClient>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </GqlClient>,
  document.getElementById('root'),
);
