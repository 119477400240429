import React, { useEffect, useState } from 'react';

import { Logo } from '../../sharedComponents/';
import { Header, Content } from '../../LayoutComponents/';

import { MAX_TOKEN_ID } from '../../../../constants';
import { getImageUrl } from '../../../../helpers';
import houdini_json_hashmap from './assets/houdini_json_hashmap.json';

import ActionPanel from './ActionPanel';
import MetaData from './MetaData';

// const FETCH_FLIPSIDE_MUTATION = loader('../../../../services/hasura/mutations/postToFlipside.graphql');

export default function StepSuccess({
  goToNextStep,
  //
  userName,
  userWalletDetails,
  address,
  //
  tx,
  userProvider,
  localProvider,
  externalContracts,
  writeContracts,
  gasPrice,
}) {
  /*
  const [data, setData] = useState([]);

  const [executeFlipsideQuery, { data: flipsideMutationResult, loading, error: flipsideMutationeError }] =
    useMutation(FETCH_FLIPSIDE_MUTATION);

  useEffect(() => {
    // TODO: pass user address
    // user_address = '${address}'
    const query = {
      sql: `
        select
          *
        from
          ethereum.core.ez_current_balances
        where
          user_address = '0x34aa3f359a9d614239015126635ce7732c18fdf3'
      `,
      ttlMinutes: '5',
    };

    async function fetchData() {
      console.log('await executeFlipsideQuery() ...');
      const flipsideQueryResult = await executeFlipsideQuery({
        variables: { ...query },
      });

      console.log({ flipsideQueryResult });

      const {
        data: {
          postToFlipside: { rows, records },
        },
      } = flipsideQueryResult;

      console.log({ records });

      if (records.length) setData(records);
    }

    fetchData();
  }, [executeFlipsideQuery]);
  */

  const [randomTotemId, setRandomTotemId] = useState(null);

  useEffect(() => {
    if (!randomTotemId) {
      const creatureIndexList = Array.from(Array(MAX_TOKEN_ID + 1).keys());

      const _randomTotemId = creatureIndexList[Math.floor(Math.random() * creatureIndexList.length)];
      setRandomTotemId(_randomTotemId);
    }
  }, [randomTotemId]);

  console.log({ randomTotemId });

  const totemMetaData = houdini_json_hashmap[randomTotemId] || {};
  const totemImageUrl = getImageUrl({ tokenId: randomTotemId, size: 2048 });

  return (
    <>
      <Header>
        <div className="bg-black bg-opacity-60 pb-5 mb-5">
          <div className="text-center my-5" style={{ fontSize: 30, textAlign: 'center', whiteSpace: 'nowrap' }}>
            <Logo />
          </div>
          <h1 className="text-4xl text-center text-white">{userName}'s Moon Totem:</h1>
        </div>
      </Header>

      <Content>
        <div className="px-5" style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ minWidth: '40%', maxWidth: '460px', justifyContent: 'center' }}>
            <img className="w-full" src={totemImageUrl} alt="Mint it now!" />

            <MetaData totemMetadata={totemMetaData} />

            <div className="my-10">
              <ActionPanel
                userWalletDetails={userWalletDetails}
                address={address}
                tokenId={randomTotemId}
                tx={tx}
                externalContracts={externalContracts}
                writeContracts={writeContracts}
                userProvider={userProvider}
                localProvider={localProvider}
                gasPrice={gasPrice}
              />
            </div>

            {/*<AdminSection data={data} address={address} />*/}

            {/*<
            <div className="flex" style={{ marginTop: '5%' }}>
              <img
                src="https://moontotems.blob.core.windows.net/totems/base/jpeg/2048/moontotems_g1_base_2048_475.jpg"
                alt="MoonTotem#475"
                style={{
                  alignSelf: 'flex-end',
                  height: 'auto',
                  width: '30%',
                  marginLeft: '5%',
                }}
              />

              <SpeechBubble
                bubblePointerLeft={true}
                style={{
                  alignSelf: 'flex-start',
                  marginLeft: '15%',
                  marginRight: '5%',
                  marginBottom: '5%',
                }}
              >
                <div className="p-5 text-left text-white text-2xl">
                  <p>A very good choice!</p>
                  <br />
                  <p>Buy it now for 0.01 ETH and join the community!</p>
                </div>
              </SpeechBubble>
            </div>
          */}
          </div>
        </div>
      </Content>
    </>
  );
}
