import React from 'react';
import { Form } from 'antd';

import { Logo } from '../../sharedComponents/';
import { Header, Content, ContentFooter, Footer } from '../../LayoutComponents/';

import SimpleSelect from './SimpleSelect';
import COUNTRY_LIST from './COUNTRY_LIST';

export default function Step03({ goToNextStep }) {
  const Titles = () => <h1 className="text-4xl text-center text-white">What is your country of birth?</h1>;

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    const values = await form.validateFields();
    console.log({ values });
    goToNextStep();
  };

  return (
    <>
      <Header>
        <div className="bg-black bg-opacity-60 p-5 mb-5">
          <div className="text-center my-5" style={{ fontSize: 30, textAlign: 'center', whiteSpace: 'nowrap' }}>
            <Logo />
          </div>

          <Titles />
        </div>
      </Header>

      <Content>
        <div className="flex p-5" style={{ justifyContent: 'center' }}>
          <div className="p-4 bg-black bg-opacity-60 rounded-xl" style={{ width: '100%', maxWidth: '460px' }}>
            <Form form={form} layout="vertical" className="w-full">
              {/*
              <label className="block text-white" htmlFor="name">
                What is your country of birth?
              </label>
              */}
              <Form.Item name="country" rules={[{ required: false, message: 'Please enter your country of birth' }]}>
                <SimpleSelect items={COUNTRY_LIST} />
              </Form.Item>

              <div className="flex items-baseline justify-between mt-2">
                <button
                  onClick={handleSubmit}
                  className="w-full px-12 py-4 mt-4 text-white text-lg rounded-lg bg-blue-700 disabled:bg-blue-400 hover:bg-blue-800"
                >
                  Next
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Content>

      <Footer className="flex" style={{ justifyContent: 'center' }}>
        <div className="flex" style={{ maxWidth: '1050px', justifyContent: 'center' }}></div>
      </Footer>
    </>
  );
}
