import React from 'react';

export default function Header({ children, style }) {
  return (
    <div
      style={{
        zIndex: 1,
        //backgroundColor: 'lightblue',
        ...style,
      }}
    >
      {children}
    </div>
  );
}
