import { useEffect, useState } from 'react';
import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk';

// https://github.com/ethwarsaw-hackathon/monorepo/blob/866b84752a0554d3738ba7b67d8165971f098a8e/packages/frontend/src/components/RampWidget.tsx

const HOST_APP_NAME = 'MoonTotems Funnel';
const HOST_LOGO_URL = 'https://memberrdev.blob.core.windows.net/dev/memberr_logo.png';

export default function RampWidget({ userAddress, show, amount, onEvent }) {
  const [rampInstance, setRampInstance] = useState();
  useEffect(() => {
    if (show && !rampInstance) {
      // docs: https://docs.ramp.network/configuration
      setRampInstance(
        new RampInstantSDK({
          hostAppName: HOST_APP_NAME,
          hostLogoUrl: HOST_LOGO_URL,
          //fiatValue: amount,
          swapAmount: amount,
          //swapAsset: 'ETH_DAI',
          swapAsset: 'ETH',
          fiatCurrency: 'EUR',
          userAddress, // user blockchain address
        })
          .on('*', onEvent)
          .show(),
      );
    }
  }, [rampInstance, userAddress, show, amount, onEvent]);

  console.log({ rampInstance });
  return null;
}
