// TODO: remove package (we are importing browser compatible file in public folder)
//import keythereum from 'keythereum';

const generateWalletFile = ({ password }) => {
  console.log('now generateWalletFile :');

  // optional private key and initialization vector sizes in bytes
  // (if params is not passed to create, keythereum.constants is used by default)
  const params = { keyBytes: 32, ivBytes: 16 };

  const keythereum = window.keythereum;

  console.log({ keythereum });

  // synchronous
  const dk = keythereum.create(params);

  console.log({ dk });

  const OPTIONS = {
    kdf: 'pbkdf2',
    cipher: 'aes-128-ctr',
    kdfparams: {
      c: 262144,
      dklen: 32,
      prf: 'hmac-sha256',
    },
  };

  // file content
  const keyObject = keythereum.dump(password, dk.privateKey, dk.salt, dk.iv, OPTIONS);

  console.log({ keyObject });

  const walletFile = keythereum.exportToFile(keyObject);
  console.log({ walletFile });

  const privateKeyBuffer = keythereum.recover(password, keyObject);

  const privateKey = privateKeyBuffer.toString('hex');

  const walletObject = keyObject;
  const address = `0x${keyObject.address}`;
  const walletFileName = `UTC--${new Date().toISOString()}--${keyObject.address}.json`;

  console.log({ address });
  console.log({ walletFileName });

  return { walletFile, walletFileName, walletObject, address, privateKey };
};

export default generateWalletFile;
