import React, { useState } from 'react';
import Blockies from 'react-blockies';
import { useLookupAddress } from 'eth-hooks/dapps/ens';
import { DocumentDuplicateIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { blockExplorerLink } from '../../helpers';

export default function Address(props) {
  const address = props.value || props.address;
  const ens = useLookupAddress(props.ensProvider, address);
  const ensSplit = ens && ens.split('.');
  const validEnsCheck = ensSplit && ensSplit[ensSplit.length - 1] === 'eth';
  const etherscanLink = blockExplorerLink(address, props.blockExplorer);
  let displayAddress = address?.substr(0, 5) + '...' + address?.substr(-3);

  const [addressCopied, setAddressCopied] = useState(false);

  const copyAddress = e => {
    e.stopPropagation();
    navigator.clipboard.writeText(address);
    setAddressCopied(true);
    setTimeout(() => {
      setAddressCopied(false);
    }, 800);
  };

  if (validEnsCheck) {
    displayAddress = ens;
  } else if (props.size === 'short') {
    //displayAddress += '...' + address.substr(-4);
  } else if (props.size === 'long') {
    displayAddress = address;
  }

  // Skeleton UI
  if (!address) {
    return (
      <div className="animate-pulse flex space-x-4">
        <div className="rounded-md bg-slate-300 h-6 w-6"></div>
        <div className="flex items-center space-y-6">
          <div className="h-2 w-28 bg-slate-300 rounded"></div>
        </div>
      </div>
    );
  }

  if (props.minimized) {
    return (
      <a target="_blank" href={etherscanLink} rel="noopener noreferrer">
        <Blockies className="inline rounded-md" size={8} scale={2} seed={address.toLowerCase()} />
      </a>
    );
  }

  return (
    <div className="flex items-center">
      {props.disableAddressLink ? (
        <>
          <input
            disabled
            value={`📖 ${displayAddress}`}
            className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none bg-white"
          />
          {/* <span className="text-lg font-normal text-gray-900 dark:text-white">📖 {displayAddress}</span> */}
        </>
      ) : (
        <a
          className="text-lg font-normal text-gray-900 dark:text-white"
          target="_blank"
          href={etherscanLink}
          rel="noopener noreferrer"
        >
          📖 {displayAddress}
        </a>
      )}
    </div>
  );
}
