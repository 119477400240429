import React from 'react';

export default function Logo() {
  return (
    <img
      src="/images/moon_totem_logo_512.png"
      alt="MoonTotemsLogo"
      className="h-[70px] w-[70px]"
      style={{
        whiteSpace: 'normal',
        display: 'inline-block',
        verticalAlign: 'middle',
      }}
    />
  );
}
